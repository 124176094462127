<template>
  <div class="card">
    <header class="card-header">
      <div class="columns is-multiline card-header-title">
        <div class="column is-12">
          <p class="modal-card-title">
            {{ titleCard }}
          </p>
        </div>
      </div>
    </header>
    <div class="card-content has-text-centered" :class="{ 'is_border' : whitBorder }">
      <template v-if="previewImage">
        <figure class="image">
          <template v-if="typeFile === 'pdf'">
            <img style="width: 30px" :src="require('@/assets/icon/pdf-file-icon.png')" alt="PDF">
          </template>
          <template v-else>
            <img :src="previewImage" :alt="previewImage">
          </template>
        </figure>
      </template>
      <template v-else>
        <span class="material-icons insert_drive_file_icon">
          insert_drive_file
        </span>
      </template>
      <div class="file is-justify-content-center" v-if="modeEdit">
        <label class="file-label">
          <input
            class="file-input"
            :name="nameInputFile"
            type="file"
            @change="setFile"
            :accept="accept"
            v-validate="validate"
            data-vv-as="de la portada"
          >
          <span class="file-cta">
            <span class="file-icon"><span class="material-icons">cloud_upload</span></span>
            <span class="file-label">{{ previewImage ? 'Actualizar' : 'Subir' }}</span>
          </span>
        </label>
      </div>
      <span v-if="!modeEdit">
        {{filename}}
      </span>
      <span v-show="vverrors.has(nameInputFile)" class="help is-danger">{{ vverrors.first(nameInputFile) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  name: 'CardUploadFile',
  props: {
    whitBorder: {
      type: Boolean,
      default: false
    },
    titleCard: {
      type: String,
      default: null
    },
    previewImage: {
      default: null
    },
    setFile: {
      type: Function,
      default: () => {}
    },
    modeEdit: {
      type: Boolean,
      default: false
    },
    validate: {
      default: null
    },
    accept: {
      default: null
    },
    nameInputFile: {
      type: String,
      default: 'fileUpload'
    },
    typeFile: {
      type: String,
      default: 'image'
    },
    filename: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }
  .card-header {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    border-bottom: solid 1px #e8e8e8;
  }
  .card-content.is_border {
    border-bottom: solid 1px #e8e8e8;
    border-left: solid 1px #e8e8e8;
    border-right: solid 1px #e8e8e8;
  }

  .file-cta {
    border-radius: 8px;
    border: solid 2px var(--primary-black);
    color: var(--primary-black);
    background-color: white;
  }
  .file-cta:hover {
    border: solid 2px var(--primary-black);
    color: white;
    background-color: var(--primary-black);
  }

  .image {
    margin: 1rem auto;
  }
  .image img {
    width: 70%;
    margin: auto;
  }

  .insert_drive_file_icon {
    color: var(--primary-gray);
    font-size: 60px;
    margin: 1rem 0;
  }
</style>
